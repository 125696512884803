import Cookies from "js-cookie";
import { getEnv } from "utils/Redirections";
import store from "store";

export const saveTwoFactorAuthData = (data) => {
  const { domain, email, two_factor_token, qr_image, qr_code } = data;
  const env = process.env.REACT_APP_ENV ? getEnv() : "localhost";

  Cookies.set("2fa-t", two_factor_token, {
    expires: 1,
    domain: env,
  });
  Cookies.set("2fa-c", qr_code, {
    expires: 1,
    domain: env,
  });
  Cookies.set("2fa-domain", domain, {
    expires: 1,
    domain: env,
  });
  Cookies.set("2fa-email", email, {
    expires: 1,
    domain: env,
  });
  localStorage.setItem("2fa-img", qr_image);
};
export const oracleMobileAppAuthConfig = {
  apple:
    "https://apps.apple.com/us/app/oracle-mobile-authenticator/id835904829",
  android:
    "https://play.google.com/store/apps/details?id=oracle.idm.mobile.authenticator",
};

export const twoFactorAuthPayload = () => ({
  domain: Cookies.get("2fa-domain"),
  email: Cookies.get("2fa-email"),
  two_factor_token: Cookies.get("2fa-t"),
});
export const twoFactorAuthData = () => ({
    qr_image: localStorage.getItem("2fa-img"),
    qr_code: Cookies.get("2fa-c"),
});
export const otpFormate = (otp) => store.getState().locale.lang === "ar"
? otp?.split("")?.reverse()?.join("")
: otp
export const brandedCompanyPortal = () => {
  document.documentElement.style.setProperty(
    "--company-primary-color",
    localStorage.getItem("company-primary-color")
  );
  document.documentElement.style.setProperty(
    "--company-secondary-color",
    localStorage.getItem("company-secondary-color")
  );
}