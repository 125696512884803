import "./App.scss";
import React, { Component } from "react";
import { Router } from "react-router-dom";
import history from "../routes/History";
import Routes from "../routes/Routes";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import messages from "../assets/Local/messages";
import { Loader } from "components/Loader/Loader";
import { SnackbarComponent } from "components/Snackbar/Snackbar";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import ScrollToTop from "react-scroll-to-top";
import { Helmet } from "react-helmet";
import { setCurrentLang } from "store/actions/Lang";
import { getURLParams } from "utils/Shared";

class App extends Component {
  constructor() {
    super();
    this.state = {
      pathname: "/",
    };
  }

  componentDidMount() {
    const locale = getURLParams("locale");
    console.log(process.env)
    if (locale && (locale === "ar" || locale === "en")) this.props.setCurrentLang(locale);
    const trackingId = process.env.REACT_APP_COMPANY_GOOGLE_ANALYTICS_KEY;
    if (
      !window.location.href.includes("https://admin-stage-hrcom.ibtikar.sa") &&
      !window.location.href.includes("https://admin-stage.hrcom.site") &&
      !window.location.href.includes("https://admin.hrcom.site")
    ) {
      ReactGA.initialize(trackingId);
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_KEY,
      });
    }

    localStorage.getItem("lang")
      ? localStorage.setItem("lang", localStorage.getItem("lang"))
      : localStorage.setItem("lang", this.props.lang);

    const pathRoute = history.location.pathname;
    const pageName = pathRoute === "/" ? "home" : pathRoute;
    this.setState({ pathname: history.location.pathname });
    pathRoute !== "/companyLogin" &&
      pathRoute !== "/registration" &&
      ReactGA.pageview(`${pathRoute}`, [], pageName);
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "yzspq3ie"
      });
      window.Intercom("update");
  }

  componentDidUpdate() {
    if (history.location.pathname !== this.state.pathname) {
      this.setState({ pathname: history.location.pathname });
      const pathRoute = history.location.pathname;
      const pageName = pathRoute === "/" ? "home" : pathRoute;
      pathRoute !== "/companyLogin" &&
        pathRoute !== "/registration" &&
        ReactGA.pageview(`${pathRoute}`, [], pageName);
    }
  }

  render() {
    const { lang, loading } = this.props;
    const scrollStyle = {
      left: lang === "ar" ? "40px" : "auto",
      right: lang === "ar" ? "auto" : "40px",
    };
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <div
          className={lang === "ar" ? "rtl" : "ltr"}
          dir={lang === "ar" ? "rtl" : "ltr"}
        >
          {loading ? <Loader /> : null}
          <Router history={history}>
            <SnackbarComponent />
            <ScrollToTop smooth top={300} style={scrollStyle} />
            {Routes}
          </Router>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale.lang,
    loading: state.loading,
  };
};

export default connect(mapStateToProps, {
  setCurrentLang,
})(App);
